<template>
  <v-container>
    <v-form ref="form">
      <base-material-card icon="mdi-ticket-account">
        <slot slot="title">Your Box Offices</slot>
        <slot slot="rightButtons">
          <toggle-button text="Add a new Box Office" @toggle="showAdd" :showing="showCreateBoxOfficeToggle"/>
        </slot>
        <div class="mt-10"/>
        <div v-if="showCreateBoxOfficeToggle">
          <p>Your Box Office is the home for all your events.  It should be the name of your organisation.  You can change it at any time.</p>
          <v-text-field label="New Box Office Name" v-model="newBoxOfficeName" :rules="[utils.requiredRule]">
            <template v-slot:append-outer>
              <v-icon large @click="createBoxOffice">mdi-checkbox-marked-circle</v-icon>
              <v-icon large @click="showAdd(false)">mdi-close-circle</v-icon>
            </template>
          </v-text-field>
        </div>
        <v-row class="mx-n1 clickableRow underlinedRow" v-for="(boxOffice, i) in boxOffices" :key="i" @click="selectItem(boxOffice)">
          <v-col>{{boxOffice.name}}</v-col>
        </v-row>
      </base-material-card>
    </v-form>
  </v-container>
</template>
  
<script>
  import Api from '@/services/Api.js'; 
  import Store from '@/services/Store.js';
  import Utils from '@/services/Utils.js'
  export default {
    name: 'Dashboard',
    components: {
      BaseMaterialCard: () => import('@/components/base/MaterialCard'),
      ToggleButton: () => import('./components/ToggleButton'),
    },  

    data () {
      return {
        boxOffices: [],
        showCreateBoxOfficeToggle: false,
        newBoxOfficeName: "",
        user: null,
        valid: false,
        rules: {
          required: value => !!value || 'Required.'
        }
      }
    },

    created() {
      this.load(); 
    },
    
    computed: {
      utils: function () {
        return Utils;
      },
    },
    
    methods: {

      async load() {
        this.user = await Api.post(this, "User", "currentUser", null, true);
        if (this.user) {
          await this.readBoxOffices();
        }
        if (this.boxOffices.length < 1) {
          this.$router.push("/Dashboard/Wizard")
        }
      },

      showAdd(show) {
        this.showCreateBoxOfficeToggle = show;
      },

      async createBoxOffice() {
        var boxOffice = {}
        boxOffice.name = this.newBoxOfficeName;
        boxOffice.email = this.user.email;
        await Api.post(this, "BoxOffice", "create", boxOffice);
        this.newBoxOfficeName = "";
        this.showAdd(false);
        this.readBoxOffices();
      },

      async readBoxOffices() {
        this.boxOffices = await Api.post(this, "BoxOffice", "list");
      },

      selectItem(boxOffice) {
        Store.store(boxOffice);
        this.$router.push("/Dashboard/Events/" + boxOffice.id)
      },
    }
  }

</script>
